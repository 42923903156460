import React from "react"
import SEO from "../components/seo"
import { auth, useAuth } from "gatsby-theme-firebase"
import Button from "gatsby-theme-firebase/src/components/Button"
import Bonuses from "../components/bonuses"
import LoginModal from "../components/loginModal"
import Layout from "../components/layout"
import "../styles/account.scss"
import firebase from "gatsby-plugin-firebase"
import { firestore } from "gatsby-theme-firebase"
import { CheckoutForm } from "../gatsby-theme-firebase/components/SignUpForm"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import ConfirmAction from "../components/confirmationModal"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC)

export default () => {
  const { isLoading, isLoggedIn, profile } = useAuth()
  const [userData, loadUserData] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [currentCategory, setCategory] = React.useState(0)
  const [displayName, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [status, setStatus] = React.useState("")

  React.useEffect(() => {
    if (profile && profile.email) {
      setName(profile.displayName)
      setEmail(profile.email)
      if (userData === null)
        firestore
          .doc(`users/${profile.uid}`)
          .get()
          .then(user => {
            loadUserData(user.data())
          })
    }
  }, [profile])

  React.useEffect(() => {
    if (status !== null) {
      setTimeout(() => {
        setStatus(null)
      }, 6000)
    }
  }, [status])

  if (isLoading) {
    return (
      <Layout>
        <SEO title="Acount" />
        {isLoading && (
          <div className="dark-overlay">
            <div className="spinner" />
          </div>
        )}
      </Layout>
    )
  }
  const setErrorMessage = message => {
    if (message !== null) setStatus({ type: "error", message })
  }

  const updateUser = async () => {
    setStatus(null)
    var user = profile
    try {
      if (email !== profile.email) {
        await user.updateEmail(email)
      }
      if (password.length) {
        user.updatePassword(password)
      }
      await user.updateProfile({
        displayName,
      })
      setStatus({ type: "success", message: "Updated successfully!" })
    } catch (error) {
      setStatus({ type: "error", message: error.message })
    }
  }
  const updateCard = async token => {
    try {
      await firebase.functions().httpsCallable("changePayment")({
        token,
      })
      setLoading(false)
      setStatus({ type: "success", message: `Credit card changed!` })
    } catch (error) {
      console.log(error)
      setLoading(false)
      setErrorMessage(error.message)
    }
  }
  const cancelSubscription = async () => {
    try {
      setLoading(true)
      const cancel_at = await firebase
        .functions()
        .httpsCallable("cancelAccount")()
      setLoading(false)
      setStatus({
        type: "success",
        message: `Your account will be deleted on ${new Date(
          cancel_at.data
        ).toLocaleDateString()}.`,
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
      setErrorMessage(error.message)
    }
  }

  const accountInfo =
    currentCategory === 0 ? (
      <div className="account-info">
        <label>
          Change Your <b>Name</b> (not legally - just what we call you here)
        </label>
        <input
          type="text"
          value={displayName}
          onChange={event => {
            setName(event.target.value)
          }}
        />
        <label>
          Change Your <b>Email</b>{" "}
        </label>
        <input
          type="text"
          value={email}
          onChange={event => {
            setEmail(event.target.value)
          }}
        />
        <label>
          Change Your <b>Password</b>{" "}
        </label>
        <input
          type="password"
          value={password}
          onChange={event => {
            setPassword(event.target.value)
          }}
        />
        <Button
          onClick={() => {
            updateUser()
          }}
        >
          Submit
        </Button>
      </div>
    ) : null
  const billingInfo =
    currentCategory === 1 ? (
      <div className="billing-info">
        {/* {userData !== null && <div>You're on tier {userData.tier}</div>} */}

        <label>Change Your Credit Card</label>
        <Elements stripe={stripePromise}>
          <CheckoutForm
            update={true}
            submitPayment={updateCard}
            setErrorMessage={setErrorMessage}
            loading={loading}
            setLoading={setLoading}
          />
        </Elements>
        <br />
        <label>⚠️Danger Zone⚠️</label>
        <ConfirmAction
          buttonText="Cancel Subscription"
          badConfirm={true}
          confirmAction={cancelSubscription}
          titleText="Cancel Your Subscription?"
          bodyText={
            <span>
              Cancelling now will stop your subscription at the end of your
              billing cycle. You will not be able to access any courses (that
              you did not purchase separately) or live events.
            </span>
          }
          confirmButtonText="Yes - remove all my access at the end of my billing cycle!"
          cancelButtonText="No, wait! I want to stay subscribed to Millionaire Millennial!"
        />
      </div>
    ) : null

  const bonusInfo =
    currentCategory === 2
      ? userData &&
        userData.bonus && (
          <Bonuses displayName={displayName} bonuses={userData.bonus} />
        )
      : null

  const categories = [accountInfo, billingInfo, bonusInfo]

  return (
    <Layout>
      <SEO title="Acount" />
      {isLoggedIn ? (
        <div>
          <h2>
            Hello {profile.displayName}, welcome to your account settings.
          </h2>
          <div className="row">
            <div className="column30">
              <div className="account-menu">
                <Button
                  className="btn-account-nav"
                  onClick={() => {
                    setCategory(0)
                  }}
                >
                  Account
                </Button>
                <Button
                  className="btn-account-nav"
                  onClick={() => {
                    setCategory(1)
                  }}
                >
                  Billing
                </Button>
                <Button
                  style={{ background: "green" }}
                  className="btn-account-nav"
                  onClick={() => {
                    setCategory(2)
                  }}
                >
                  Bonuses
                </Button>
                <Button
                  className="btn-account-nav"
                  onClick={() => {
                    typeof window !== 'undefined' && localStorage.removeItem("MM_User")
                    auth.signOut()
                  }}
                >
                  Sign Out
                </Button>
              </div>
            </div>
            <div className="column70">
              <div className="account-main">
                <div
                  className={`${
                    status && status.type === "error" ? "errors" : "success"
                  } ${status ? "show" : "hidden"}`}
                >
                  <div>{status && status.message}</div>
                </div>

                {categories[currentCategory]}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ margin: "auto", maxWidth: 300 }}>
          Login to view your account information.
          <LoginModal course={{}} />
        </div>
      )}
    </Layout>
  )
}
