import React from "react"
import Button from "gatsby-theme-firebase/src/components/Button"
import "../styles/login.scss"

export default ({
  buttonText,
  badConfirm,
  confirmAction,
  titleText,
  bodyText,
  confirmButtonText,
  cancelButtonText,
}) => {
  const [toggleModal, setToggleModal] = React.useState(false)
  return (
    <>
      <div>
        <Button
          className={badConfirm ? "btn-danger" : "btn-good"}
          onClick={() => {
            setToggleModal(true)
          }}
        >
          {buttonText ? buttonText : "Confirm"}
        </Button>
      </div>
      {toggleModal && (
        <div>
          <div
            className="dark-overlay"
            onClick={() => {
              setToggleModal(false)
            }}
          />
          <div className="modal-popup">
          <div className="modal-title">{titleText}</div>
            <div className="modal-body">
              
              <div className="modal-text">{bodyText}</div>
              <Button
                className={!badConfirm ? "btn-danger" : "btn-good"}
                onClick={() => {
                  setToggleModal(false)
                }}
              >
                {cancelButtonText}
              </Button>
              <Button
                className={badConfirm ? "btn-danger" : "btn-good"}
                onClick={() => {
                  confirmAction()
                  setToggleModal(false)
                }}
              >
                {confirmButtonText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
