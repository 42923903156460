import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Button from "gatsby-theme-firebase/src/components/Button"
import variablesInMd from "../utils/variablesinMD"

export default ({ bonuses, displayName }) => {
  const [selectedBonus, selectBonus] = useState(null)
  const query = useStaticQuery(graphql`
    query {
      autobot: file(relativePath: { eq: "icons/messenger_autobot.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 645) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      email_icon: file(relativePath: { eq: "icons/email_icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      niche_creator: file(relativePath: { eq: "icons/niche_creator.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(
        filter: {
          internal: { mediaType: { eq: "text/markdown" } }
          sourceInstanceName: { eq: "bonuses" }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              html
              frontmatter {
                title
                slug
              }
            }
          }
        }
      }
    }
  `)
  const allBonuses = {
    nicheCreator: {
      title: "Niche Creator",
      image: <Image fluid={query.niche_creator.childImageSharp.fluid} />,
      link: "nicheCreator",
    },
    copyConversionKit: {
      title: "Copy Conversion Kit",
      image: <Image fluid={query.email_icon.childImageSharp.fluid} />,
      link: "copyConversionKit",
    },
    messengerAutobot: {
      title: "Messenger Autobot",
      image: <Image fluid={query.autobot.childImageSharp.fluid} />,
      link: "messengerAutobot",
    },
  }
  const { allFile } = query
  const { edges } = allFile
  if (selectedBonus !== null) {
    const thisBonus = allBonuses[selectedBonus]
    const { link } = thisBonus
    const thisNode = edges.find(
      edge => edge.node.childMarkdownRemark.frontmatter.slug === link
    )
    if (thisNode) {
      const { html } = thisNode.node.childMarkdownRemark
      return (
        <div className="bonus-container" style={{ padding: "1rem" }}>
          <Button
            style={{ width: "20%", padding: 3, marginBottom: 10 }}
            onClick={() => selectBonus(null)}
          >
            ↶ Back
          </Button>
          <div
            dangerouslySetInnerHTML={{
              __html: variablesInMd(html, {
                FIRSTNAME: displayName,
              }),
            }}
          />
        </div>
      )
    } else
      return (
        <div className="bonus-container" style={{ padding: "1rem" }}>
          <Button
            style={{ width: "20%", padding: 3, marginBottom: 10 }}
            onClick={() => selectBonus(null)}
          >
            ↶ Back
          </Button>
          <br />
          Not Found... :(
        </div>
      )
  } else
    return (
      <div className="bonus-container">
        <h3>Here's Where All Your Bonuses Will Be!</h3>
        <div className="items">
          {Object.values(bonuses) &&
            Object.values(bonuses).map((course, i) => {
              return (
                course &&
                course.map((bonus, j) => {
                  const thisBonus = allBonuses[bonus]
                  return thisBonus ? (
                    <div
                      key={j}
                      className="item hover-zoom"
                      onClick={() => {
                        selectBonus(thisBonus.link)
                      }}
                    >
                      <div className="image-holder">{thisBonus.image}</div>
                      <div className="top-text">{thisBonus.title}</div>
                    </div>
                  ) : null
                })
              )
            })}
        </div>
      </div>
    )
}
